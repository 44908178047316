import React, { useState, useEffect } from 'react'
import './GeographySection.scss'
import SectionTitle from '../SectionTitle'
import NetherlandsComponent from './countries-svg/Netherlands'
import PolandComponent from './countries-svg/Poland'
import UkraineComponent from './countries-svg/Ukraine'
import RussiaComponent from './countries-svg/Russia'
import UAEComponent from './countries-svg/UAE'
import GermanyComponent from './countries-svg/Germany'
import ItalyComponent from './countries-svg/Italy'
import IndiaComponent from './countries-svg/India'
import ChinaComponent from './countries-svg/China'
import HongKongComponent from './countries-svg/HongKong'
import { graphql, useStaticQuery } from 'gatsby'

const GeographySection = ({ language }) => {
    const { cockpitCountries, cockpitCountriesRu } = useStaticQuery(graphql`
        query CountryQuery {
            cockpitCountries {
                Title {
                    value
                }
                Countries {
                    value {
                        China {
                            value
                        }
                        Germany {
                            value
                        }
                        India {
                            value
                        }
                        HongKong {
                            value
                        }
                        Italy {
                            value
                        }
                        Netherlands {
                            value
                        }
                        Poland {
                            value
                        }
                        Russia {
                            value
                        }
                        Uae {
                            value
                        }
                        Ukraine {
                            value
                        }
                    }
                }
            }
            cockpitCountriesRu: cockpitCountries(lang: { eq: "ru" }) {
                Title {
                    value
                }
                Countries {
                    value {
                        China {
                            value
                        }
                        Germany {
                            value
                        }
                        India {
                            value
                        }
                        HongKong {
                            value
                        }
                        Italy {
                            value
                        }
                        Netherlands {
                            value
                        }
                        Poland {
                            value
                        }
                        Russia {
                            value
                        }
                        Uae {
                            value
                        }
                        Ukraine {
                            value
                        }
                    }
                }
            }
        }
    `)
    const { Title, Countries } = cockpitCountries
    const {
        Netherlands,
        Poland,
        Ukraine,
        Russia,
        Germany,
        Uae,
        Italy,
        India,
        China,
        HongKong,
    } = Countries.value
    const { Title: TitleRu, Countries: CountriesRu } = cockpitCountriesRu
    const {
        Netherlands: NetherlandsRu,
        Poland: PolandRu,
        Ukraine: UkraineRu,
        Russia: RussiaRu,
        Germany: GermanyRu,
        Uae: UaeRu,
        Italy: ItalyRu,
        India: IndiaRu,
        China: ChinaRu,
        HongKong: HongKongRu,
    } = CountriesRu.value

    const [active, setActive] = useState('uae')
    const [localWidth, setLocalWidth] = useState(0)
    useEffect(() => {
        const elemCoordinates = document
            .querySelector('.export')
            .getBoundingClientRect().top

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', () => {
            if (window.scrollY >= scrollTop + elemCoordinates) {
                setLocalWidth(72)
            }
        })
    }, [])

    const countries = [
        {
            className: 'netherlands',
            title: language === 'en' ? 'Netherlands' : 'Нидерланды',
            description:
                language === 'en' ? Netherlands.value : NetherlandsRu.value,
            component: (
                <NetherlandsComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en'
                            ? Netherlands.value
                            : NetherlandsRu.value
                    }
                />
            ),
        },
        {
            className: 'poland',
            title: language === 'en' ? 'Poland' : 'Польша',
            description: language === 'en' ? Poland.value : PolandRu.value,
            component: (
                <PolandComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? Poland.value : PolandRu.value
                    }
                />
            ),
        },
        {
            className: 'ukraine',
            title: language === 'en' ? 'Ukraine' : 'Украина',
            description: language === 'en' ? Ukraine.value : UkraineRu.value,
            component: (
                <UkraineComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? Ukraine.value : UkraineRu.value
                    }
                />
            ),
        },
        {
            className: 'russia',
            title: language === 'en' ? 'Russia' : 'Россия',
            description: language === 'en' ? Russia.value : RussiaRu.value,
            component: (
                <RussiaComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? Russia.value : RussiaRu.value
                    }
                />
            ),
        },
        {
            className: 'uae',
            title: language === 'en' ? 'UAE' : 'ОАЭ',
            description: language === 'en' ? Uae.value : UaeRu.value,
            component: (
                <UAEComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={language === 'en' ? Uae.value : UaeRu.value}
                />
            ),
        },
        {
            className: 'germany',
            title: language === 'en' ? 'Germany' : 'Германия',
            description: language === 'en' ? Germany.value : GermanyRu.value,
            component: (
                <GermanyComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? Germany.value : GermanyRu.value
                    }
                />
            ),
        },
        {
            className: 'italy',
            title: language === 'en' ? 'Italy' : 'Италия',
            description: language === 'en' ? Italy.value : ItalyRu.value,
            component: (
                <ItalyComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? Italy.value : ItalyRu.value
                    }
                />
            ),
        },
        {
            className: 'india',
            title: language === 'en' ? 'India' : 'Индия',
            description: language === 'en' ? India.value : IndiaRu.value,
            component: (
                <IndiaComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? India.value : IndiaRu.value
                    }
                />
            ),
        },
        {
            className: 'china',
            title: language === 'en' ? 'China' : 'Китай',
            description: language === 'en' ? China.value : ChinaRu.value,
            component: (
                <ChinaComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? China.value : ChinaRu.value
                    }
                />
            ),
        },
        {
            className: 'hongKong',
            title: language === 'en' ? 'Hong Kong' : 'Гонгконг',
            description: language === 'en' ? HongKong.value : HongKongRu.value,
            component: (
                <HongKongComponent
                    handleCountryClick={(country) => setActive(country)}
                    state={active}
                    description={
                        language === 'en' ? HongKong.value : HongKongRu.value
                    }
                />
            ),
        },
    ]
    return (
        <>
            <section className="export" id="export">
                <div className="container">
                    <SectionTitle
                        title={language === 'en' ? Title.value : TitleRu.value}
                        width={`${localWidth}%`}
                    />
                    <div className="countries">
                        {countries.map((item, index) => {
                            return (
                                <div
                                    className={'country ' + item.className}
                                    key={index}
                                >
                                    <h4
                                        className={
                                            active === item.className
                                                ? 'country_name active'
                                                : 'country_name'
                                        }
                                    >
                                        {item.title}
                                    </h4>
                                    {item.component}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="export-mobile">
                <div className="container">
                    <SectionTitle
                        title={language === 'en' ? Title.value : TitleRu.value}
                        width={`${localWidth}%`}
                    />
                </div>
                <div className="countries-mobile">
                    {countries.map((item, index) => {
                        return (
                            <div
                                className={
                                    active === item.className
                                        ? 'country-mobile_wrapper active'
                                        : 'country-mobile_wrapper'
                                }
                                key={index}
                                onClick={() => setActive(item.className)}
                            >
                                <div className="container">
                                    <div className="country-mobile">
                                        <div className="svg_title-mobile">
                                            {item.component}
                                            <h4
                                                className={
                                                    active === item.className
                                                        ? 'country_name active'
                                                        : 'country_name'
                                                }
                                            >
                                                {item.title}
                                            </h4>
                                        </div>
                                        <i
                                            className={
                                                active === item.className
                                                    ? 'fas fa-angle-down active'
                                                    : 'fas fa-angle-down'
                                            }
                                        ></i>
                                    </div>
                                    {active === item.className && (
                                        <div className="country_description-mobile">
                                            {item.description}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default GeographySection
