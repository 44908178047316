import React, { useState, useEffect } from 'react'
import './AdvantagesSection.scss'
import SectionTitle from '../SectionTitle'
import { graphql, useStaticQuery } from 'gatsby'

const AdvantagesSection = ({ language }) => {
    const { cockpitAdvantages, cockpitAdvantagesRu } = useStaticQuery(graphql`
        query AdvantagesQuery {
            cockpitAdvantages {
                Image {
                    value {
                        publicURL
                    }
                }
                Advantages {
                    value {
                        first {
                            value
                        }
                        second {
                            value
                        }
                        third {
                            value
                        }
                    }
                }
                Title {
                    value
                }
            }
            cockpitAdvantagesRu: cockpitAdvantages(lang: { eq: "ru" }) {
                Advantages {
                    value {
                        first {
                            value
                        }
                        second {
                            value
                        }
                        third {
                            value
                        }
                    }
                }
                Title {
                    value
                }
            }
        }
    `)
    const { Image, Advantages, Title } = cockpitAdvantages
    const { first, second, third } = Advantages.value
    const { Advantages: AdvantagesRu, Title: TitleRu } = cockpitAdvantagesRu
    const {
        first: firstRu,
        second: secondRu,
        third: thirdRu,
    } = AdvantagesRu.value
    const [localWidth, setLocalWidth] = useState(0)
    useEffect(() => {
        const elemCoordinates = document
            .querySelector('.choice')
            .getBoundingClientRect().top

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', () => {
            if (window.scrollY >= scrollTop + elemCoordinates) {
                setLocalWidth(43)
            }
        })
        return () => {
            window.removeEventListener('scroll')
        }
    }, [])
    const reasons = [
        {
            title: language === 'en' ? first.value : firstRu.value,
            className: 'one',
        },
        {
            title: language === 'en' ? second.value : secondRu.value,
            className: 'two',
        },
        {
            title: language === 'en' ? third.value : thirdRu.value,
            className: 'three',
        },
    ]
    return (
        <section className="choice" id="choice">
            <div
                className="hands"
                style={{
                    backgroundImage: `url('https://rosrbiz.firebaseapp.com${Image.value.publicURL}')`,
                }}
            >
                <div className="desktop_why-we">
                    <SectionTitle
                        title={language === 'en' ? Title.value : TitleRu.value}
                        width={`${localWidth}%`}
                    />
                </div>
            </div>
            <div className="reasons">
                <div className="phone_why-we">
                    <SectionTitle
                        title={language === 'en' ? Title.value : TitleRu.value}
                        width={`${localWidth}%`}
                    />
                </div>
                <div className="reason_wrapper-mobile">
                    {reasons.map((item, index) => {
                        return (
                            <div
                                className={'reason ' + item.className}
                                key={index}
                            >
                                <span className="number">{index + 1}</span>
                                <p className="reason_content">{item.title}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default AdvantagesSection
