import React, { useEffect, useState } from 'react'
import './AboutSection.scss'
import SectionTitle from '../SectionTitle'
import asia from '../../images/Asia.png'
import speed from '../../images/principles/speed.png'
import quality from '../../images/principles/quality.png'
import complexity from '../../images/principles/complexity.png'
import experience from '../../images/principles/experience.png'
import { graphql, useStaticQuery } from 'gatsby'

const AboutSection = ({ language }) => {
    const { cockpitAbout, cockpitAboutRu } = useStaticQuery(graphql`
        query AboutQuery {
            cockpitAbout {
                Title {
                    value
                }
                Description {
                    value
                }
                ListTitle {
                    value
                }
                ListItems {
                    value {
                        first {
                            value
                        }
                        second {
                            value
                        }
                        third {
                            value
                        }
                        fourth {
                            value
                        }
                    }
                }
                ImageDesktop {
                    value {
                        publicURL
                    }
                }
            }
            cockpitAboutRu: cockpitAbout(lang: { eq: "ru" }) {
                Title {
                    value
                }
                Description {
                    value
                }
                ListTitle {
                    value
                }
                ListItems {
                    value {
                        first {
                            value
                        }
                        second {
                            value
                        }
                        third {
                            value
                        }
                        fourth {
                            value
                        }
                    }
                }
            }
        }
    `)

    const { Title, Description, ListTitle, ListItems, ImageDesktop } =
        cockpitAbout
    const { first, second, third, fourth } = ListItems.value
    const {
        Title: TitleRu,
        Description: DescriptionRu,
        ListTitle: ListTitleRu,
        ListItems: ListItemsRu,
    } = cockpitAboutRu
    const {
        first: firstRu,
        second: secondRu,
        third: thirdRu,
        fourth: fourthRu,
    } = ListItemsRu.value

    const [localWidth, setLocalWidth] = useState(0)
    useEffect(() => {
        const elemCoordinates = document
            .querySelector('.about')
            .getBoundingClientRect().top

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', () => {
            if (window.scrollY >= scrollTop + elemCoordinates) {
                setLocalWidth(29)
            }
        })
    }, [])
    const principles = [
        {
            title: language === 'en' ? first.value : firstRu.value,
            image: speed,
        },
        {
            title: language === 'en' ? second.value : secondRu.value,
            image: quality,
        },
        {
            title: language === 'en' ? third.value : thirdRu.value,
            image: complexity,
        },
        {
            title: language === 'en' ? fourth.value : fourthRu.value,
            image: experience,
        },
    ]
    return (
        <section className="about" id="about">
            <div className="about_us">
                <img className="asia" src={asia} alt="" />
                <SectionTitle
                    title={language === 'en' ? Title.value : TitleRu.value}
                    width={localWidth}
                />
                <div className="text">
                    {language === 'en'
                        ? Description.value
                        : DescriptionRu.value}
                </div>
                <div className="grey_principles">
                    <div className="principles_wrapper">
                        <h3 className="principle_title">
                            {language === 'en'
                                ? ListTitle.value
                                : ListTitleRu.value}
                        </h3>
                        <div className="principles">
                            {principles.map((item, index) => {
                                return (
                                    <div className="principle" key={index}>
                                        <img src={item.image} alt="" />
                                        <h4 className="principle_subtitle">
                                            {item.title}
                                        </h4>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="about_image"
                style={{
                    backgroundImage: `url('https://rosrbiz.web.app${ImageDesktop.value.publicURL}')`,
                }}
            ></div>
        </section>
    )
}

export default AboutSection
