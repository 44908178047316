import React, { useState, useEffect } from 'react'
import './Partners.scss'
import SectionTitle from '../SectionTitle'
import { graphql, useStaticQuery } from 'gatsby'

const PartnersSection = ({ language }) => {
    const { cockpitPartners, cockpitPartnersRu } = useStaticQuery(graphql`
        query PartnersQuery {
            cockpitPartners {
                Title {
                    value
                }
                PartnersImages {
                    value {
                        publicURL
                    }
                }
            }
            cockpitPartnersRu: cockpitPartners(lang: { eq: "ru" }) {
                Title {
                    value
                }
            }
        }
    `)
    const { Title, PartnersImages } = cockpitPartners
    const { Title: TitleRu } = cockpitPartnersRu
    const [localWidth, setLocalWidth] = useState(0)
    useEffect(() => {
        const elemCoordinates = document
            .querySelector('.clients')
            .getBoundingClientRect().top

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', () => {
            if (window.scrollY >= scrollTop + elemCoordinates) {
                setLocalWidth(86)
            }
        })
    }, [])
    return (
        <section className="clients" id="clients">
            <div className="container">
                <SectionTitle
                    title={language === 'en' ? Title.value : TitleRu.value}
                    width={`${localWidth}%`}
                />
                <div className="partners_wrapper">
                    <div className="partners">
                        {PartnersImages.value.map((partner, index) => {
                            return (
                                <div className="partner" key={index}>
                                    <img
                                        src={`https://rosrbiz.web.app${partner.publicURL}`}
                                        alt=""
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection
