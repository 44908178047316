import { graphql, useStaticQuery } from 'gatsby'
import React, { useState, useEffect } from 'react'
import SectionTitle from '../SectionTitle'
import Field from './Field'
import './FieldActivitySection.scss'

const FieldActivitySection = ({ language }) => {
    const { cockpitFieldOfActivity, cockpitFieldOfActivityRu } =
        useStaticQuery(graphql`
            query ActivityQuery {
                cockpitFieldOfActivity {
                    Title {
                        value
                    }
                    ActivityTitles {
                        value {
                            first {
                                value
                            }
                            second {
                                value
                            }
                            third {
                                value
                            }
                        }
                    }
                    ActivityContent {
                        value {
                            first {
                                value
                            }
                            second {
                                value
                            }
                            third {
                                value
                            }
                        }
                    }
                    ActivityImages {
                        value {
                            publicURL
                        }
                    }
                }
                cockpitFieldOfActivityRu: cockpitFieldOfActivity(
                    lang: { eq: "ru" }
                ) {
                    Title {
                        value
                    }
                    ActivityTitles {
                        value {
                            first {
                                value
                            }
                            second {
                                value
                            }
                            third {
                                value
                            }
                        }
                    }
                    ActivityContent {
                        value {
                            first {
                                value
                            }
                            second {
                                value
                            }
                            third {
                                value
                            }
                        }
                    }
                }
            }
        `)

    const { Title, ActivityTitles, ActivityContent, ActivityImages } =
        cockpitFieldOfActivity
    const {
        Title: TitleRu,
        ActivityTitles: ActivityTitlesRu,
        ActivityContent: ActivityContentRu,
    } = cockpitFieldOfActivityRu

    const [localWidth, setLocalWidth] = useState(0)
    useEffect(() => {
        const elemCoordinates = document
            .querySelector('.fields_of_activity')
            .getBoundingClientRect().top

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', () => {
            if (window.scrollY >= scrollTop + elemCoordinates) {
                setLocalWidth(58)
            }
        })
        return () => {
            window.removeEventListener('scroll')
        }
    }, [])

    return (
        <section className="fields_of_activity" id="fields_of_activity">
            <div className="container">
                <SectionTitle
                    title={language === 'en' ? Title.value : TitleRu.value}
                    width={localWidth}
                />
                <div className="fields">
                    <Field
                        image={ActivityImages.value[0].publicURL}
                        title={
                            language === 'en'
                                ? ActivityTitles.value.first.value
                                : ActivityTitlesRu.value.first.value
                        }
                        text={
                            language === 'en'
                                ? ActivityContent.value.first.value
                                : ActivityContent.value.first.value
                        }
                    />
                    <Field
                        image={ActivityImages.value[1].publicURL}
                        title={
                            language === 'en'
                                ? ActivityTitles.value.second.value
                                : ActivityTitlesRu.value.second.value
                        }
                        text={
                            language === 'en'
                                ? ActivityContent.value.second.value
                                : ActivityContent.value.second.value
                        }
                    />
                    <Field
                        image={ActivityImages.value[2].publicURL}
                        title={
                            language === 'en'
                                ? ActivityTitles.value.third.value
                                : ActivityTitlesRu.value.third.value
                        }
                        text={
                            language === 'en'
                                ? ActivityContent.value.third.value
                                : ActivityContent.value.third.value
                        }
                    />
                </div>
            </div>
        </section>
    )
}

export default FieldActivitySection
