import React, { useContext, useState } from 'react'
import { Context } from '../../context'
import './Navbar.scss'
import instagram from '../../images/sm/instagram.png'
import facebook from '../../images/sm/facebook.png'
import google from '../../images/sm/google.png'
import { graphql, useStaticQuery } from 'gatsby'
import logo from '../../images/ASIA-TRADE.svg'

const Navbar = ({ isOpen, close }) => {
  const { navigation, setLang } = useContext(Context)

  const { cockpitNavbar } = useStaticQuery(graphql`
    query NavQuery {
      cockpitNavbar {
        Instagram {
          value
        }
        GooglePlus {
          value
        }
        Facebook {
          value
        }
        LogoName {
          value
        }
      }
    }
  `)
  const { Facebook, GooglePlus, Instagram, LogoName } = cockpitNavbar

  const [isChecked, setIsChecked] = useState(true)

  const hanldeCheck = () => {
    setIsChecked(!isChecked)
    setLang(isChecked ? 'ru' : 'en')
  }

  return (
    <div
      className="grey_nav"
      style={isOpen ? { transform: 'translateX(0%)' } : null}
    >
      <div className="close" onClick={close}>
        &times;
      </div>
      <div className="nav_wrapper">
        <div>
          <div className="logo">
            <img src={logo} alt="" />
            {LogoName.value}
          </div>
          <nav className="nav">
            <ul>
              {navigation.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={`#${item.id}`} onClick={close}>
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
        <div className="lang">
          <span className={!isChecked ? 'ru active' : 'ru'}>Ru</span>
          <input type="checkbox" checked={isChecked} onChange={hanldeCheck} />
          <span className={isChecked ? 'en active' : 'en'}>En</span>
        </div>
      </div>
      <div className="social_media">
        <a href={Instagram.value}>
          <img src={instagram} alt="" />
        </a>
        <a href={Facebook.value}>
          <img src={facebook} alt="" />
        </a>
        <a href={GooglePlus.value}>
          <img src={google} alt="" />
        </a>
      </div>
    </div>
  )
}

export default Navbar
