import React from 'react'
import './SectionTitle.scss'

const SectionTitle = ({ title, width }) => {
    return (
        <>
            <h2 className="section_title">{title}</h2>
            <div className="indicator">
                <div className="yellow_line" style={{ width }}></div>
            </div>
        </>
    )
}

export default SectionTitle
