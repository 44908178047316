import React, { useContext } from 'react'
import './Footer.scss'
import { Context } from '../../context'
import phone from '../../images/phone-white.png'
import email from '../../images/email-white.png'
import logo from '../../images/ASIA-TRADE.svg'
import { graphql, useStaticQuery } from 'gatsby'

const Footer = () => {
  const context = useContext(Context)
  const { cockpitContacts } = useStaticQuery(graphql`
    query ContactsQuery2 {
      cockpitContacts {
        Email {
          value
        }
        Phone {
          value
        }
      }
    }
  `)
  const { Email, Phone } = cockpitContacts
  return (
    <footer className="footer">
      <div className="footer_wrapper">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <nav>
          <ul>
            {context.navigation.map((item, index) => {
              return (
                <li key={index}>
                  <a href={`#${item.id}`}>{item.name}</a>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className="phones">
          <span>
            <img src={phone} alt="phone_number" />
            <a href={`tel:${Phone.value}`}>{Phone.value}</a>
          </span>
          <span>
            <img src={email} alt="email" />
            <a href={`mailto:${Email.value}`}>{Email.value}</a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
