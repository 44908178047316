import React, { useState, useEffect, useRef } from 'react'
import './ContactsSection.scss'
import SectionTitle from '../SectionTitle'
import Footer from '../Footer'
import phone from '../../images/phone.png'
import email from '../../images/email.png'
import { graphql, useStaticQuery } from 'gatsby'

const ContactsSection = ({ language }) => {
  const { cockpitContacts, cockpitContactsRu } = useStaticQuery(graphql`
    query ContactsQuery {
      cockpitContacts {
        ButtonText {
          value
        }
        ContactWithUsText {
          value
        }
        Description {
          value
        }
        Email {
          value
        }
        Phone {
          value
        }
        Title {
          value
        }
        Image {
          value {
            publicURL
          }
        }
      }
      cockpitContactsRu: cockpitContacts(lang: { eq: "ru" }) {
        ButtonText {
          value
        }
        ContactWithUsText {
          value
        }
        Description {
          value
        }
        Title {
          value
        }
      }
    }
  `)
  const {
    ButtonText,
    ContactWithUsText,
    Description,
    Email,
    Phone,
    Title,
    Image,
  } = cockpitContacts

  const {
    ButtonText: ButtonTextRu,
    ContactWithUsText: ContactWithUsTextRu,
    Description: DescriptionRu,
    Title: TitleRu,
  } = cockpitContactsRu

  const nameRef = useRef()
  const phoneRef = useRef()
  const [localWidth, setLocalWidth] = useState(0)
  useEffect(() => {
    const elemCoordinates = document
      .querySelector('.contacts_footer_wrapper')
      .getBoundingClientRect().top

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    window.addEventListener('scroll', () => {
      if (window.scrollY + 300 >= scrollTop + elemCoordinates) {
        setLocalWidth(100)
      }
    })
  }, [])

  const submitForm = async (e) => {
    e.preventDefault()
    const nameValue = nameRef.current.value
    const phoneValue = phoneRef.current.value

    if (nameValue.length > 2 && phoneValue) {
      console.log(123)
      const res = await fetch('/sendData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: nameValue,
          phone: phoneValue,
        }),
      })
    }
  }
  return (
    <>
      <section className="contacts_footer_wrapper" id="contacts">
        <div className="contacts">
          <div className="container">
            <SectionTitle
              title={language === 'en' ? Title.value : TitleRu.value}
              width={`${localWidth}%`}
            />
            <div className="contact_with_us">
              <div className="text">
                {language === 'en' ? Description.value : DescriptionRu.value}
              </div>
              <form className="form" onSubmit={submitForm}>
                <input
                  className="input"
                  type="text"
                  placeholder={language === 'en' ? 'Name' : 'Ваше имя'}
                  ref={nameRef}
                  required
                />
                <input
                  className="input"
                  type="text"
                  placeholder={language === 'en' ? 'Phone' : 'Ваш телефон'}
                  ref={phoneRef}
                  required
                />
                <button type="submit" className="button">
                  {language === 'en' ? ButtonText.value : ButtonTextRu.value}
                </button>
              </form>
            </div>
            <div>
              <h4 className="contact_sign">
                {language === 'en'
                  ? ContactWithUsText.value
                  : ContactWithUsTextRu.value}
              </h4>
              <div className="phones">
                <span>
                  <img src={phone} alt="phone_number" />
                  <a href={`tel:${Phone.value}`}>{Phone.value}</a>
                </span>
                <span>
                  <img src={email} alt="email" />

                  <a href={`mailto:${Email.value}`}>{Email.value}</a>
                </span>
              </div>
            </div>
          </div>
          <div
            className="managers"
            style={{
              backgroundImage: `url('https://rosrbiz.firebaseapp.com${Image.value.publicURL}')`,
            }}
          ></div>
        </div>
      </section>
    </>
  )
}

export default ContactsSection
