import * as React from 'react'
import Seo from '../components/seo'
import { Context } from '../context'
import './index.scss'
import MainSection from '../components/Main'
import About from '../components/About'
import Advantages from '../components/Advantages'
import Geography from '../components/Geography'
import Partners from '../components/Partners'
import Contacts from '../components/Contacts'
import Footer from '../components/Footer'
import { graphql, useStaticQuery } from 'gatsby'
import FieldActivity from '../components/FieldOfActivity'

const IndexPage = () => {
  const { cockpitNavbar, cockpitNavbarRu } = useStaticQuery(graphql`
    query NavItemsQuery {
      cockpitNavbar {
        NavigationItems {
          value {
            To_About_Section {
              value
            }
            To_Advantages_Section {
              value
            }
            To_Clients_Section {
              value
            }
            To_Contacts_Section {
              value
            }
            To_Main_Section {
              value
            }
          }
        }
      }
      cockpitNavbarRu: cockpitNavbar(lang: { eq: "ru" }) {
        NavigationItems {
          value {
            To_About_Section {
              value
            }
            To_Advantages_Section {
              value
            }
            To_Clients_Section {
              value
            }
            To_Contacts_Section {
              value
            }
            To_Main_Section {
              value
            }
          }
        }
      }
    }
  `)

  const {
    To_About_Section,
    To_Advantages_Section,
    To_Clients_Section,
    To_Contacts_Section,
    To_Main_Section,
  } = cockpitNavbar.NavigationItems.value

  const {
    To_About_Section: To_About_Section_Ru,
    To_Advantages_Section: To_Advantages_Section_Ru,
    To_Clients_Section: To_Clients_Section_Ru,
    To_Contacts_Section: To_Contacts_Section_Ru,
    To_Main_Section: To_Main_Section_Ru,
  } = cockpitNavbarRu.NavigationItems.value

  const [language, setLanguage] = React.useState('en')
  const navigation = [
    {
      name:
        language === 'en' ? To_Main_Section.value : To_Main_Section_Ru.value,
      id: 'main',
    },
    {
      name:
        language === 'en' ? To_About_Section.value : To_About_Section_Ru.value,
      id: 'about',
    },
    {
      name:
        language === 'en'
          ? To_Advantages_Section.value
          : To_Advantages_Section_Ru.value,
      id: 'choice',
    },
    {
      name:
        language === 'en'
          ? To_Clients_Section.value
          : To_Clients_Section_Ru.value,
      id: 'clients',
    },
    {
      name:
        language === 'en'
          ? To_Contacts_Section.value
          : To_Contacts_Section_Ru.value,
      id: 'contacts',
    },
  ]

  return (
    <Context.Provider
      value={{ navigation, setLang: (lang) => setLanguage(lang) }}
    >
      <Seo
        title="ASIA TRADE"
        description="Asia Trade?
                Asia Trade is a young company with incredibly experienced employees, 
                which is engaged in the export of various materials."
      />
      <MainSection language={language} />
      <About language={language} />
      <Advantages language={language} />
      <FieldActivity language={language} />
      <Geography language={language} />
      <Partners language={language} />
      <Contacts language={language} />
      <Footer />
    </Context.Provider>
  )
}

export default IndexPage
