import React from 'react'
import ShowMoreText from 'react-show-more-text'

const Field = ({ image, title, text }) => {
    return (
        <div className="field">
            <div className="field_image">
                <img src={`https://rosrbiz.web.app${image}`} alt="" />
            </div>
            <div className="field_content">
                <h2 className="field_title">{title}</h2>
                <div className="field_text">
                    <ShowMoreText
                        lines={
                            typeof window !== 'undefined' &&
                            window.innerWidth > 480
                                ? false
                                : 4
                        }
                        more="Read more &nbsp; &#9660;"
                        less="Hide &nbsp; &#9650;"
                        truncatedEndingComponent={'...'}
                        anchorClass="accordion"
                    >
                        {text}
                    </ShowMoreText>
                </div>
            </div>
        </div>
    )
}

export default Field
